import SidebarService from 'src/components/ui/SidebarService/SidebarService'
import './styles.scss'

const ServicesPage = () => {
  return (
    <>
      <section className="section-services w-full">
        <div className="av-container">
          <div className="av-row section-services__flex">
            <div className="av-col-xs-24 av-col-md-7 section-services__column">
              <SidebarService />
            </div>
            <div className="av-col-xs-24 av-col-md-17">
              {/* <!- Opções-> */}
              <div className="services-content">
                <div className="section__top align-center flex flex-col-reverse xl:flex-col lg:flex-col md:flex-col sm:flex-col-reverse xs:flex-col-reverse px-6 py-0">
                  <h1 className="text-blue italic text-2xl font-semibold uppercase hidden md:flex">
                    SELECIONE UMA DAS OPÇÕES AO LADO
                  </h1>
                  <p className="text-left hidden md:flex">
                    Selecione uma das opções ao lado
                  </p>
                  {/* /*Text Mobile */}
                  <p className="text-left md:hidden">
                    Selecione uma das opções a cima
                  </p>
                  <h1 className="text-blue italic text-2xl font-semibold uppercase md:hidden">
                    SELECIONE UMA DAS OPÇÕES ACIMA
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ServicesPage
